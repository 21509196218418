import {
  Stack,
  Button,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  CircularProgress
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import AdminHead from '../component/AdminHead';
import Cookies from 'js-cookie';

function PhotoUpload() {
  
  const [isLoading, setIsLoading] = useState(false);
  const type = "wildlife";
  const user = Cookies.get('userToken');

  const formik = useFormik({
    initialValues: {
      picName: '',
      files: null, // Change the initial value to null
    },
    validationSchema: Yup.object({
      picName: Yup.string().required('Picture Name is required'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append('pic_name', values.picName);
        formData.append('files', values.files); // Use values.files to get the selected file
        formData.append('type', type);

        const response = await axios.post(
          `${process.env.REACT_APP_CLIENT_URL}upload`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${user}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        setIsLoading(false);
        formik.setFieldValue('files', null); // Reset the file input field
        alert('Data stored');
        
      } catch (error) {
        setIsLoading(false);
        alert(error);
        console.error('Error posting data:', error);
      }
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue('files', file);
  };

  return (
    <>
      <AdminHead />
      <Heading
        alignSelf="center"
        fontSize="2xl"
        flex="1"
        textColor="white"
        textAlign="center"
        bgColor="gray.700"
        pt={10}
        pb={10}
      >
        Wildlife Upload
      </Heading>
      <VStack bgColor="gray.700" minH="100vh">
        <VStack
          as="form"
          mx="auto"
          w={['90%', '80%', '80%', '50%', '50%']}
          justifyContent="center"
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          spacing="5"
          padding={['5', '10', '20']}
          border="2px solid black"
          rounded={20}
        >
          <FormControl
            isRequired
            isInvalid={formik.errors.picName && formik.touched.picName}
          >
            <FormLabel textColor="white">Pic Name</FormLabel>
            <Input
              name="picName"
              onChange={formik.handleChange}
              value={formik.values.picName}
              placeholder="candle girl"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.picName}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            alignItems="center"
            isInvalid={
              (formik.errors.file || formik.errors.photo) &&
              (formik.touched.file || formik.touched.photo)
            }
          >
            <FormLabel textColor="white">Banner Image</FormLabel>
            <Input
              textColor="white"
              onChange={handleFileChange}
              type="file"
              name="files" // Should match the name used in formData.append('file', watermarkFile);
            />
          </FormControl>

          <Button
            type="submit"
            variant="outline"
            color="white"
            isLoading={isLoading}
          >
            {isLoading ? (
              <CircularProgress isIndeterminate size="24px" color="teal.400" />
            ) : (
              'Submit'
            )}
          </Button>
        </VStack>
      </VStack>
    </>
  );
}

export default PhotoUpload;
