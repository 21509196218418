import { 
    Stack,
    DrawerHeader, 
    Button, 
    DrawerBody, 
    DrawerContent, 
    Drawer, 
    DrawerOverlay,  
    useDisclosure, 
    Heading,
    VStack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    HStack,
    Link
   } from "@chakra-ui/react"
  import React from 'react'
  import { useFormik  } from 'formik'
  import * as Yup from "yup"
  import { HamburgerIcon } from '@chakra-ui/icons'
  import { FiLogOut } from "react-icons/fi";
import Cookies from "js-cookie"
  // FiLogOut

function AdminHead() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [placement] = React.useState('left')
    const logout = () => {
      Cookies.remove("userToken");
      window.location.href = "/"; // Make sure 'window' is lowercase, and use quotes around the URL.
    }
    
  return (
    <Stack direction="row" bgColor="gray.700" p={15} w="100%" h="20%"  >
        
    <HamburgerIcon onClick={onOpen} color="white" w={10} h={10}  cursor="pointer" />
    <Heading alignSelf="center" flex="1" textColor="white" textAlign="center" > Admin Panel </Heading>
    <FiLogOut fontSize={24} style={{marginRight:"20px", color:"white"}} onClick={logout} />
    <Drawer  placement={placement} onClose={onClose}  isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent  >
        <DrawerHeader borderBottomWidth='1px' textColor="white" bgColor="gray.700" >Navigation</DrawerHeader>
        <DrawerBody bgColor="gray.700" >
          <Link href="/wildlifeupload">
            <Heading p={5} textColor="white" fontSize="lg"> Wildlife upload </Heading>
          </Link>
          <Link href="/allwildlife">
            <Heading p={5} textColor="white" fontSize="lg"> Wildlifes </Heading>
          </Link>
          <Link href="/photoupload">
            <Heading p={5} textColor="white" fontSize="lg"> Photo upload  </Heading>
          </Link>
          <Link href="/allphoto">
            <Heading p={5} textColor="white" fontSize="lg"> Photos  </Heading>
          </Link>
          <Link href="/safari">
            <Heading p={5} textColor="white" fontSize="lg"> Safari </Heading>
          </Link>
          <Link href="/safariadd">
            <Heading p={5} textColor="white" fontSize="lg"> Safari Add </Heading>
          </Link>
            {/* <Heading p={5} textColor="white" fontSize="lg"> Tourist details </Heading> */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  </Stack>
  )
}

export default AdminHead