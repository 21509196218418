import {
  Stack,
  Button,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  CircularProgress,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import AdminHead from '../component/AdminHead';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

const ImageUploadButton = ({ onImageSelect, selectedImages }) => {
  const handleImageSelect = event => {
    const files = event.target.files;
    const selectedFiles = Array.from(files).slice(0, 1); // Limit the selection to 1 image
    onImageSelect(selectedFiles[0]);
  };

  return (
    <div>
      <Input
        type="file"
        accept="image/*"
        multiple={true}
        id="image-upload"
        onChange={handleImageSelect}
        style={{ display: 'none' }}
        disabled={selectedImages.length >= 10} // Disable the button when 6 images are selected
      />
      <Button
        style={
          selectedImages.length >= 10 ? { display: 'none' } : { display: '' }
        }
        onClick={() => {
          document.getElementById('image-upload').click();
        }}
        disabled={selectedImages.length >= 10}
      >
        <span className="mr-2">+</span>
        Upload Images
      </Button>
    </div>
  );
};

function EditPhoto() {
  const [isLoading, setIsLoading] = useState(false);
  const [image_data, setImageData] = useState(null);
  const { userId } = useParams();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedWatermarkImages, setWatermarkSelectedImages] = useState([]);

  const handleImageSelect = selectedFile => {
    const objectUrl = URL.createObjectURL(selectedFile);
    setSelectedImages([
      ...selectedImages,
      { _id: 'new', url: objectUrl,img:objectUrl, _file: selectedFile },
    ]);
  };

  const handleRemoveImage = index => {
    setSelectedImages(prevImages =>
      prevImages.filter((image, i) => i !== index)
    );
    setWatermarkSelectedImages(prevImages =>
      prevImages.filter((image, i) => i !== index)
    );
  };
  
  useEffect(() => {
    // Define the API endpoint based on whether an `id` is available
    const apiUrl = `${process.env.REACT_APP_CLIENT_URL}get-watermark/${userId}`;

    // Fetch images based on the API endpoint
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => fetchInitialData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Fetch initial data from the server
  const fetchInitialData = async data => {
    setImageData(data);
    formik.values.name = data.data.name;
    formik.values.email = data.data.email;
    formik.values.phone = data.data.number;

    const initialSelectedImages = data.data.watermarked_file.map(image => ({
      url: `https://api.rohitkandothphotography.com/uploads/${image}`,
      name:image
    }));
    const initialWatermarkSelectedImages = data.data.original_file.map(image => ({
      url: `https://api.rohitkandothphotography.com/uploads/${image}`,
      name:image
    }));
    setSelectedImages(initialSelectedImages);
    setWatermarkSelectedImages(initialWatermarkSelectedImages);

    console.log(data);
  };

  const handleUpdate = async () => {
    setIsLoading(true)
    // Upload images and get image URLs
    const uploadedImageUrls = [];
    const waterMarkImageUrls = [];
    console.log(selectedImages.length);
    for (let i = 0; i < selectedImages.length; i++) {
      console.log(selectedImages[i].img);
      if (selectedImages[i].img!=undefined) {
        const uploadFile = new FormData();
        uploadFile.append('file', selectedImages[i]._file);
        uploadFile.append('type', image_data.data.type);
        try {
          setIsLoading(true)
          const response = await axios.post(
            process.env.REACT_APP_CLIENT_URL + 'upload/image',
            uploadFile,
            {
              headers: {
                Authorization: 'Bearer ' + Cookies.get('userToken'), // Replace with your actual access token
              },
            }
          );
          uploadedImageUrls.push(response.data.original);
          waterMarkImageUrls.push(response.data.watermark);
          setIsLoading(false)
        } catch (error) {
          setIsLoading(true)
          console.error(error);
          setIsLoading(false)
        }
      } else {
        uploadedImageUrls.push(selectedImages[i].name);
        waterMarkImageUrls.push(selectedWatermarkImages[i].name);
      }
      setIsLoading(false)
    }

    const images = uploadedImageUrls.map(imageUrl => ({ name: imageUrl }));

    const formData = {
      original_file: uploadedImageUrls.length === 0 ? [] : uploadedImageUrls,
      watermarked_file:uploadedImageUrls.length === 0 ? [] : waterMarkImageUrls,
      id: userId,
    };
    //console.log(formData);
    // Send the updated data to the server
    axios
      .post(process.env.REACT_APP_CLIENT_URL + 'upload/edit', formData, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('userToken'), // Replace with your access token
        },
      })
      .then(response => {
        setIsLoading(true)
        alert('Product Updated');
        setIsLoading(false)
        // window.location.reload();

        //console.log(response);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          alert(error.response.data.message);
        } else {
          //console.log(error);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      name: image_data === null ? '' : image_data.name,
      email: image_data === null ? '' : image_data.email,
      phone: image_data === null ? '' : image_data.phone,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Person name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone: Yup.number().min(10).required('Phone No. is required'),
    }),
    onSubmit: handleUpdate,
  });

  return (
    <>
      <AdminHead />
      <Heading
        alignSelf="center"
        fontSize="2xl"
        flex="1"
        textColor="white"
        textAlign="center"
        bgColor="gray.700"
        pt={10}
        pb={10}
      >
        Edit Photo
      </Heading>
      <VStack bgColor="gray.700" minH="100vh">
        <VStack
          as="form"
          mx="auto"
          w={['90%', '80%', '80%', '50%', '50%']}
          justifyContent="center"
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          spacing="5"
          padding={['5', '10', '20']}
          border="2px solid black"
          rounded={20}
        >
          <FormControl
            isRequired
            isInvalid={formik.errors.name && formik.touched.name}
          >
            <FormLabel textColor="white">Name</FormLabel>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="naruto uzumaki"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.errors.email && formik.touched.email}
          >
            <FormLabel textColor="white">Email</FormLabel>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="naruto@gmail.com"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.errors.phone && formik.touched.phone}
          >
            <FormLabel textColor="white">Contact Number</FormLabel>
            <Input
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              placeholder="8928656498"
              size="md"
              type="number"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
          </FormControl>

          <FormControl alignItems="center">
            <FormLabel textColor="white">Photos</FormLabel>

            <SimpleGrid marginTop={1} marginBottom={1} columns={4}>
              {selectedImages.map((image, index) => (
                <div key={index} className="relative w-25 px-2 mb-4">
                  <img
                    src={image.key?image.key:image.url} // Use the URL stored in the 'url' property
                    alt={`Image ${index + 1}`}
                    onClick={() => handleRemoveImage(index)}
                  />
                  <Button
                    onClick={() => handleRemoveImage(index)}
                    marginBottom={1}
                    marginTop={1}
                    textAlign={'center'}
                    width={100}
                    // className="absolute top-0 left-2 w-4 h-4 flex items-center justify-center rounded-full bg-red-500 text-white"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </SimpleGrid>
            <ImageUploadButton
              onImageSelect={handleImageSelect}
              selectedImages={selectedImages}
            />
          </FormControl>

          {/* <FormControl isInvalid={formik.errors.price && formik.touched.price}>
            <FormLabel textColor="white">Price</FormLabel>
            <Input
              name="price"
              onChange={formik.handleChange}
              value={formik.values.price}
              placeholder="24" // Remove the dollar sign
              textColor="white"
            />

            <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
          </FormControl> */}

          <Button
            type="submit"
            variant="outline"
            color="white"
            isLoading={isLoading}
          >
            {isLoading ? (
              <CircularProgress isIndeterminate size="24px" color="teal.400" />
            ) : (
              'Submit'
            )}
          </Button>
        </VStack>
      </VStack>
    </>
  );
}

export default EditPhoto;
