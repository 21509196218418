import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import AdminHead from '../component/AdminHead';

function Photoimages() {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_CLIENT_URL}get-all-watermark-images/photo`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Image',
        accessor: 'watermarked_file',
        Cell: ({ cell }) => (
          cell.value.length > 0 ? (
            <img src={`https://api.rohitkandothphotography.com/uploads/${cell.value[0]}`} alt="Image" style={{ width: '100px' }} />
          ) : null
        ),
      },
      {
        Header: 'Edit',
        accessor: '_id',
        Cell: ({ cell }) => (
          <Link to={`/editphoto/${cell.value}`}>Edit</Link>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => imageData, [imageData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  return (
    <>
      <AdminHead />
      <div style={{ backgroundColor: '#2d3748', minHeight: '100vh', padding: '50px' }}>
        <div style={{ width: '80%', margin: '0 auto' }}>
          <h1
            style={{
              fontSize: '2xl',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#1a202c',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            All Photos
          </h1>
          <input
            type="text"
            placeholder="Search..."
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
            style={{
              marginBottom: '10px',
              width: '100%',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          />
          <table
            {...getTableProps()}
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
              marginBottom: '20px',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        backgroundColor: '#1a202c',
                        color: 'white',
                        padding: '10px',
                        textAlign: 'left',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #ccc' }}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          textAlign: 'left',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Photoimages;
