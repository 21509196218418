import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
import Admin from './pages/Admin';
import PhotoUpload from './pages/PhotoUpload';
import WildLifeUpload from './pages/WildLifeUpload';
import WidlifeImages from './pages/WidlifeImages';
import Photoimages from './pages/Photoimages';
import Cookies from 'js-cookie';
import EditWildlifeimage from './pages/EditWildlifeimage';
import EditPhoto from './pages/EditPhoto';
import Safari from './pages/Safari';
import SafariAdd from './pages/SafariAdd';
import EditSafari from './pages/EditSafari';


function Router() {
  const user = Cookies.get('userToken');
  var redirect = window.location;
  let { userId } = useParams();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Admin />} />
        {user ? (
          <Route path="/photoupload" element={<PhotoUpload />} />
        ) : (
          <Route
            path="/photoupload"
            exact
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/wildlifeupload" element={<WildLifeUpload />} />
        ) : (
          <Route
            path="/wildlifeupload"
            exact
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/allwildlife" element={<WidlifeImages />} />
        ) : (
          <Route
            path="/allwildlife"
            exact
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/allphoto" element={<Photoimages />} />
        ) : (
          <Route
            path="/allphoto"
            exact
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/editwildlife/:userId" element={<EditWildlifeimage />} />
        ) : (
          <Route
            path="/editwildlife/:userId"
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/editphoto/:userId" element={<EditPhoto />} />
        ) : (
          <Route
            path="/editphoto/:userId"
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/safari" element={<Safari />} />
        ) : (
          <Route
            path="/safari"
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/safariadd" element={<SafariAdd />} />
        ) : (
          <Route
            path="/safariadd"
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
        {user ? (
          <Route path="/editSafari/:userId" element={<EditSafari />} />
        ) : (
          <Route
            path="/editSafari/:userId"
            element={
              <Navigate
                replace
                to={'/?redirect=' + redirect.pathname.replaceAll('/', '%2F')}
              />
            }
          />
        )}
      </Routes>
      {/* {user ? <Route path="/" element={<Dashboard />} /> : <Route path="/" element={<Navigate replace to={"/signin?redirect="+redirect.pathname.replaceAll('/','%2F')} />} />} */}
    </BrowserRouter>
  );
}

export default Router;
