import {
  Stack,
  Button,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  CircularProgress,
  SimpleGrid,
  Box,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import AdminHead from '../component/AdminHead';
import Cookies from 'js-cookie';

const ImageUploadButton = ({ onImageSelect, selectedImages }) => {
  const handleImageSelect = event => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);

    // Check that selectedFiles are valid File objects
    const validFiles = selectedFiles.filter(file => file instanceof File);

    onImageSelect(validFiles); // Pass the array of selected files
  };

  return (
    <div>
      <Input
        type="file"
        accept="jpeg/jpg/png"
        multiple={true}
        id="image-upload"
        onChange={handleImageSelect}
        style={{ display: 'none' }}
        disabled={selectedImages.length >= 10}
      />
      <Button
        style={
          selectedImages.length >= 10 ? { display: 'none' } : { display: '' }
        }
        onClick={() => {
          document.getElementById('image-upload').click();
        }}
        disabled={selectedImages.length >= 10}
      >
        <span className="mr-2">+</span>
        Upload Images
      </Button>
    </div>
  );
};

function PhotoUpload() {
  const [isLoading, setIsLoading] = useState(false);
  const user = Cookies.get('userToken');
  const type = 'photo';
  const [imageURLs, setImageURLs] = useState([]); // State to store selected image URLs
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageSelect = selectedFiles => {
    // Map selectedFiles to createObjectURL
    const newImages = selectedFiles.map(selectedFile => ({
      _id: 'new',
      url: URL.createObjectURL(selectedFile),
      _file: selectedFile,
    }));

    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = index => {
    setSelectedImages(prevImages =>
      prevImages.filter((image, i) => i !== index)
    );
  };

  const resetForm = () => {
    formik.resetForm();
    setSelectedImages([]);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      width: '',
      height: '',
      files: [], // Use an array to store multiple files
      price: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Person name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone: Yup.number().min(10).required('Phone No. is required'),
    }),
    onSubmit: async values => {
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('number', values.phone);
        formData.append('width', values.width);
        formData.append('height', values.height);
        formData.append('type', type);
        selectedImages.forEach(files => {
          formData.append('files', files._file);
        });
        formData.append('price', values.price);

        const response = await axios.post(
          `${process.env.REACT_APP_CLIENT_URL}upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        setIsLoading(false);
        alert('Data stored');
        console.log('Data posted successfully:', response.data);
        // Reset the form and clear selected images
        resetForm();
      } catch (error) {
        setIsLoading(false);
        alert(error);
        console.error('Error posting data:', error);
      }
    },
  });

  return (
    <>
      <AdminHead />
      <Heading
        alignSelf="center"
        fontSize="2xl"
        flex="1"
        textColor="white"
        textAlign="center"
        bgColor="gray.700"
        pt={10}
        pb={10}
      >
        Photo Upload
      </Heading>
      <VStack bgColor="gray.700" minH="100vh">
        <VStack
          as="form"
          mx="auto"
          w={['90%', '80%', '80%', '50%', '50%']}
          justifyContent="center"
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          spacing="5"
          padding={['5', '10', '20']}
          border="2px solid black"
          rounded={20}
        >
          <FormControl
            isRequired
            isInvalid={formik.errors.name && formik.touched.name}
          >
            <FormLabel textColor="white">Name</FormLabel>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="naruto uzumaki"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.errors.email && formik.touched.email}
          >
            <FormLabel textColor="white">Email</FormLabel>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="naruto@gmail.com"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.errors.phone && formik.touched.phone}
          >
            <FormLabel textColor="white">Contact Number</FormLabel>
            <Input
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              placeholder="8928656498"
              size="md"
              type="number"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
          </FormControl>

          <FormControl alignItems="center">
            <FormLabel textColor="white">Original Image</FormLabel>
            <SimpleGrid marginTop={1} marginBottom={1} columns={4}>
              {selectedImages.map((image, index) => (
                <div key={index} className="relative w-25 px-2 mb-4">
                  <img
                    src={image.url} // Use the URL stored in the 'url' property
                    alt={`Image ${index + 1}`}
                   
                    onClick={() => handleRemoveImage(index)}
                  />
                  <Button
                    onClick={() => handleRemoveImage(index)}
                    marginBottom={1}
                    marginTop={1}
                    textAlign={'center'}
                    width={100}
                    // className="absolute top-0 left-2 w-4 h-4 flex items-center justify-center rounded-full bg-red-500 text-white"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </SimpleGrid>
            <ImageUploadButton
              onImageSelect={handleImageSelect}
              selectedImages={selectedImages}
            />
          </FormControl>

          <Button
            type="submit"
            variant="outline"
            color="white"
            isLoading={isLoading}
          >
            {isLoading ? (
              <CircularProgress isIndeterminate size="24px" color="teal.400" />
            ) : (
              'Submit'
            )}
          </Button>
        </VStack>
      </VStack>
    </>
  );
}

export default PhotoUpload;
