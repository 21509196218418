import React, { useState } from 'react';
import {
  Heading,
  Input,
  Stack,
  Button,
  useToast,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
  CircularProgress,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import AdminHead from '../component/AdminHead';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const ImageUploadButton = ({ onImageSelect, selectedImage }) => {
  const handleImageSelect = event => {
    const file = event.target.files[0];

    // Check that the selected file is a valid File object
    const isValidFile = file instanceof File;

    if (isValidFile) {
      onImageSelect(file); // Pass the selected file
    }
  };

  return (
    <div>
      <Input
        type="file"
        accept="jpeg/jpg/png"
        id="image-upload"
        onChange={handleImageSelect}
        style={{ display: 'none' }}
        disabled={selectedImage}
      />
      <Button
        style={selectedImage ? { display: 'none' } : { display: '' }}
        onClick={() => {
          document.getElementById('image-upload').click();
        }}
        disabled={selectedImage}
      >
        Upload Image
      </Button>
    </div>
  );
};

function EditSafari() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const user = Cookies.get('userToken');
  const [selectedImages, setSelectedImages] = useState([]);
  const [editorValue, setEditorValue] = useState(null);
  const { userId } = useParams();
  const [editData, setEditData] = useState(null);

  const handleImageSelect = selectedFile => {
    // Map selectedFile to createObjectURL
    const newImage = {
      _id: 'new',
      url: URL.createObjectURL(selectedFile),
      _file: selectedFile,
    };

    setSelectedImages([newImage]); // Set the selected image as an array
  };

  const handleRemoveImage = () => {
    setSelectedImages([]); // Remove the selected image
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_CLIENT_URL}Safari/layout/${userId}`;

    // Fetch images based on the API endpoint
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => fetchInitialData(data))
      .catch(error => console.error('Error fetching data:', error));
    },[])
  
     // Fetch initial data from the server
  const fetchInitialData = async data => {
    setEditData(data);
    formik.values.heading = data.data.heading;
    formik.values.tagline = data.data.tagline;
    formik.values.price = data.data.price;
    
    const initialSelectedImages = [
        {
          url: `https://api.rohitkandothphotography.com/${data.data.featured_banner}`,
        },
      ];
    
      setSelectedImages(initialSelectedImages);
      setEditorValue(data.data.Safari_body);
  };

  const resetForm = () => {
    formik.resetForm();
    setSelectedImages([]);
    setEditorValue('');
  };

  const formik = useFormik({
    initialValues: {
        heading: editData === null ?'' : editData.heading ,
        tagline: editData === null ? '' : editData.tagline ,
        price: editData === null ? '' : editData.price ,
      },      
    validationSchema: Yup.object({
        heading: Yup.string().required('Tour Heading is required'),
      tagline: Yup.string().required('Tagline is required'),
      price: Yup.string().required('Price is required'),
    }),
    onSubmit: async values => {
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append('heading', values.heading);
        formData.append('tagline', values.tagline);
        formData.append('price', values.price);
        formData.append('Safari_body', editorValue);

        selectedImages.forEach(files => {
          formData.append('files', files._file);
        });

        const response = await axios.post(
          `${process.env.REACT_APP_CLIENT_URL}Safari/layout/create`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        setIsLoading(false);

        if (response.status === 200) {
          resetForm();
          toast({
            title: 'Safari added successfully!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.error('Error submitting form:', response.statusText);

          toast({
            title: 'Error submitting form',
            description:
              'An error occurred while submitting the form. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        setIsLoading(false);

        console.error('Error submitting form:', error);

        toast({
          title: 'Error submitting form',
          description:
            'An error occurred while submitting the form. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const handleChange = content => {
    setEditorValue(content);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  return (
    <>
      <AdminHead />
      <div
        style={{
          backgroundColor: '#2d3748',
          minHeight: '100vh',
          padding: '50px',
        }}
      >
        <Heading
          alignSelf="center"
          fontSize={{ base: 'xl', md: '2xl' }}
          textColor="white"
          textAlign="center"
          bgColor="gray.700"
        >
          Update Safari
        </Heading>
        <VStack
          as="form"
          mx="auto"
          w={['90%', '80%', '80%', '70%', '70%']}
          justifyContent="center"
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          spacing="5"
          padding={['5', '10', '10']}
          textAlign="left"
        >
          <FormControl
            isRequired
            isInvalid={formik.errors.heading && formik.touched.heading}
          >
            <FormLabel textColor="white">Safari Heading</FormLabel>
            <Input
              name="heading"
              w={['90%', '80%', '80%', '70%', '70%']}
              onChange={formik.handleChange}
              value={formik.values.heading}
              placeholder="Sanjay Gandhi National Park"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.heading}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.errors.tagline && formik.touched.tagline}
          >
            <FormLabel textColor="white">Tagline</FormLabel>
            <Input
              name="tagline"
              w={['90%', '80%', '80%', '70%', '70%']}
              onChange={formik.handleChange}
              value={formik.values.tagline}
              placeholder="Tagline"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.tagline}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={formik.errors.price && formik.touched.price}
          >
            <FormLabel textColor="white">Price</FormLabel>
            <Input
              name="price"
              w={['90%', '80%', '80%', '70%', '70%']}
              onChange={formik.handleChange}
              value={formik.values.price}
              placeholder="$120"
              textColor="white"
            />
            <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
          </FormControl>
          <FormControl alignItems="center">
            <FormLabel textColor="white">Banner Image</FormLabel>
            <SimpleGrid marginTop={1} marginBottom={1} columns={1}>
              {selectedImages.map((image, index) => (
                <div key={index} className="relative w-25 px-2 mb-4">
                  <img
                    src={image.url}
                    alt={`Image ${index + 1}`}
                    onClick={() => handleRemoveImage()}
                  />
                </div>
              ))}
            </SimpleGrid>
            <ImageUploadButton
              onImageSelect={handleImageSelect}
              selectedImage={selectedImages[0]} // Pass the first selected image
            />
          </FormControl>

          <Stack textAlign="left" width="100%">
            <FormLabel textColor="white">Safari Description</FormLabel>
            <ReactQuill
              style={{ background: 'white' }}
              value={editorValue}
              modules={modules}
              onChange={handleChange}
            />
          </Stack>

          <Button
            type="submit"
            variant="outline"
            color="white"
            isLoading={isLoading}
          >
            {isLoading ? (
              <CircularProgress isIndeterminate size="24px" color="teal.400" />
            ) : (
              'Submit'
            )}
          </Button>
        </VStack>
      </div>
    </>
  );
}

export default EditSafari;
