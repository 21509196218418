import React, { useState, useEffect } from 'react';
import AdminHead from '../component/AdminHead';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import Cookies from 'js-cookie';
import { Flex } from '@chakra-ui/react';

function WildlifeImages() {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    // Define the API endpoint based on whether an `id` is available
    const apiUrl = `${process.env.REACT_APP_CLIENT_URL}get-all-watermark-images/wildlife`;

    // Fetch images based on the API endpoint
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setImageData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleDelete = imageId => {
    // Create a payload object with the image ID
    const payload = {  id: imageId };

    // Make a POST request to delete the image with the given ID
    const apiUrl = `${process.env.REACT_APP_CLIENT_URL}upload/remove`;

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('userToken'),
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.ok) {
          alert('photo deleted');
          window.location.reload();
        } else {
          console.error('Error deleting image:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Error deleting image:', error);
      });
  };

  const data = React.useMemo(() => imageData, [imageData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'pic_name',
      },
      {
        Header: 'Image',
        accessor: 'original_file',
        Cell: ({ cell }) =>
          cell.value.length > 0 ? (
            <img
              src={`https://api.rohitkandothphotography.com/uploads/${cell.value[0]}`}
              alt="Image"
              style={{ width: '100px' }}
            />
          ) : null,
      },
      // {
      //   Header: 'Edit',
      //   accessor: '_id',
      //   Cell: ({ row }) => (
      //     <Link to={`/editwildlife/${row.original._id}`}>Edit</Link>
      //   ),
      // },
        {
          Header: 'Delete',
          
          Cell: ({ row }) => (
            <button onClick={() => handleDelete(row.original._id)}>Delete</button>
          ),
        },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  return (
    <>
      <AdminHead />
      <div
        style={{
          backgroundColor: '#2d3748',
          minHeight: '100vh',
          padding: '50px',
        }}
      >
        <div style={{ width: '80%', margin: '0 auto' }}>
          <h1
            style={{
              fontSize: '2xl',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#1a202c',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            All Wildlife Images
          </h1>
          <input
            type="text"
            placeholder="Search..."
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value)}
            style={{
              marginBottom: '10px',
              width: '100%',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          />
          {data.length === 0 ? (
            <Flex justifyContent="center" alignItems="center" minH="50vh">
              <p style={{ color: 'white', textAlign: 'center' }}>
                No data available.
              </p>
            </Flex>
          ) : (
            <table
              {...getTableProps()}
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
                marginBottom: '20px',
                backgroundColor: 'white',
                borderRadius: '5px',
              }}
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          backgroundColor: '#1a202c',
                          color: 'white',
                          padding: '10px',
                          textAlign: 'left',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{ borderBottom: '1px solid #ccc' }}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: '10px',
                              textAlign: 'left',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default WildlifeImages;
