import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from '@chakra-ui/react';
import AdminHead from '../component/AdminHead';
import React, { useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import axios from 'axios';

export default function Safari() {
  const [isData, setIsData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDelete = async (id) => {
    try {
      // Open the modal for confirmation
      setDeleteId(id);
      setIsDeleteModalOpen(true);
  
      // Optionally, you can also fetch the updated data after deletion here
      // const updatedData = await fetchData();
      // setIsData(updatedData);
    } catch (error) {
      console.error('Error opening delete confirmation:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      // Send delete request
      await axios.delete(`${process.env.REACT_APP_CLIENT_URL}Safari/layout/${deleteId}`);
  
      // Update local state after successful deletion
      setIsData((prevData) => prevData.filter(item => item._id !== deleteId));
    } catch (error) {
      console.error('Error deleting data:', error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };
  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_CLIENT_URL}Safari/layout/`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(responseData => {
        // Check if responseData.data is an array before setting the state
        if (Array.isArray(responseData.data)) {
          setIsData(responseData.data);
        } else {
          console.error(
            'Invalid data format received from the API:',
            responseData
          );
          // Handle the error (e.g., set an error state, display a message)
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Handle the error (e.g., set an error state, display a message)
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Heading',
        accessor: 'heading',
      },
      {
        Header: 'Tagline',
        accessor: 'tagline',
      },
      {
        Header: 'Image',
        accessor: 'featured_banner',
        Cell: ({ cell }) => (
          <img
            src={`https://api.rohitkandothphotography.com/${cell.value}`}
            alt="Image"
            style={{ width: '100px' }}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '_id', // Use the unique identifier of your data for the accessor
        Cell: ({ cell }) => (
          <Stack direction="row" gap={5}>
            <Button
              variant="outline"
              colorScheme="#2E6FA7"
              color="white"
              backgroundColor="#2E6FA7"
              onClick={() => handleEdit(cell.value)}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              colorScheme="#2E6FA7"
              color="white"
              backgroundColor="#2E6FA7"
              onClick={() => handleDelete(cell.value)}
            >
              Delete
            </Button>
          </Stack>
        ),
      },
    ],
    [] // consider adding dependencies if columns are dynamic
  );

  const data = React.useMemo(() => isData, [isData]);

  const handleEdit = id => {
    // Implement your edit logic here, e.g., redirect to edit page or show a modal
    window.location.href = `/editSafari/${id}`;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  return (
    <>
      <AdminHead />

      <div
        style={{
          backgroundColor: '#2d3748',
          minHeight: '100vh',
          padding: '50px',
        }}
      >
       
          <Modal isOpen={isDeleteModalOpen} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Delete</ModalHeader>
              <ModalCloseButton />
              <ModalBody>Are you sure you want to delete this item?</ModalBody>

              <ModalFooter gap={2}>
                <Button colorScheme="red" onClick={confirmDelete}>
                  Delete
                </Button>
                <Button onClick={closeModal}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
       
        <div style={{ width: '80%', margin: '0 auto' }}>
          <header>
            <h1
              style={{
                fontSize: '2xl',
                color: 'white',
                textAlign: 'center',
                backgroundColor: '#1a202c',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              Safari Data
            </h1>
          </header>
          <input
            type="text"
            placeholder="Search..."
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value)}
            style={{
              marginBottom: '10px',
              width: '100%',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          />
          <table
            {...getTableProps()}
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
              marginBottom: '20px',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        backgroundColor: '#1a202c',
                        color: 'white',
                        padding: '10px',
                        textAlign: 'left',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{ borderBottom: '1px solid #ccc' }}
                  >
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          textAlign: 'left',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
